import { app } from './Api';

const postColeta = async (data) => {
  return app.post(`coleta`, data);
};
const getColetas = async (page, qtdPerPage, filter, unidade_id) => {
  return app.get(
    `coleta?page=${page}&qtdPerPage=${qtdPerPage}&filter=${filter}&unidade_id=${unidade_id}`
  );
};
const updateColeta = async (id, data) => {
  return app.put(`coleta/${id}`, data);
};
const deleteColeta = async (id) => {
  return app.delete(`coleta/${id}`);
};
const getColetaPerId = async (id) => {
  return app.get(`coleta/${id}`);
};
const pesoColeta = async (id, data) => {
    return app.put(`peso_coleta/${id}`, data);
};

export { postColeta, getColetas, updateColeta, deleteColeta, getColetaPerId, pesoColeta };

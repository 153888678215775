export const initialState = {
    page: 0,
    rowsPerPage: 10,
    nameColeta: '',
  };
  
  // ==============================|| AUTH REDUCER ||============================== //
  
  const coletaReducer = (state = initialState, actions) => {
    switch (actions.type) {
      case 'SET_PAGE_COLETA':
        return {
          ...state,
          page: actions.payload,
        };
      case 'SET_ROWS_PER_PAGE_COLETA':
        return {
          ...state,
          rowsPerPage: actions.payload,
        };
      case 'SET_NAMECOLETA_COLETA_FILTER':
        return {
          ...state,
          nameColeta: actions.payload,
        };
      case 'SET_CLEAR_COLETA_FILTER':
        return {
          ...state,
          nameColeta: '',
        };
      default:
        return state;
    }
  };
  
  export default coletaReducer;
  